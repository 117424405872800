import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import Seo from '../components/seo'

const Tos = styled.div`
  padding-top: 2%;
  padding-left: 15%;
  padding-right: 15%;
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.25em;
  }
`

const TOSPage = () => (
  <Layout>
    <Seo
      title="Terms of Service"
      description="Pocket Scholar Terms and Conditions of Use."
      path="tos"
    />
    <Tos>
      <div>
        <h2>Pocket Scholar Terms and Conditions of Use</h2>

        <p>
          By using the Pocket Scholar website ("Service"), or any services of
          Pocket Scholar LLC ("Pocket Scholar"), you agree to be bound by the
          following terms and conditions. ("Terms of Use").
        </p>

        <h3>Our Relationship</h3>

        <ol>
          <li>
            By accessing and using our products and services, you agree to
            comply with the Terms of Use listed here as well as our Privacy
            Policy.
          </li>
          <li>
            We agree to do our absolute best to provide you with quality
            services. In return, you agree to refrain from activity that
            interferes with or prohibits us from providing these services to
            you.
          </li>
          <li>
            By using our services, you agree to access them through our
            interface and not to attempt circumventing our system.
          </li>
          <li>
            We reserve the right to terminate your account or stop providing
            products and services should you violate our Terms of Use.
          </li>
          <li>
            Should you decide you no longer want to use our products, you can
            terminate your agreement at any time by asking for your account to
            be destroyed.
          </li>
          <li>
            If you're not satisfied with our services, or find that it fails to
            meet your learning needs, we will gladly refund your last payment.
            However, if you have completed more than 50% of a section, we
            reserve the right to decline a refund.
          </li>
        </ol>

        <h3>Your Responsibilities</h3>

        <ol>
          <li>You must be 13 years of age or older to use this Service.</li>
          <li>
            You must provide a valid email address, and any other required
            information.
          </li>
          <li>You must ensure your account information remains accurate.</li>
          <li>
            You are responsible for the safekeeping of your account credentials.
            Account activity is your responsibility, so we suggest keeping this
            information in a safe place.
          </li>
          <li>Your account may only be used by one person.</li>
          <li>
            You are responsible for content posted and any activity that occurs
            under your account.
          </li>
          <li>
            We will not be liable for decisions you make or your actions beyond
            the scope of our products.
          </li>
          <li>
            You may not use the Service for any illegal or unauthorized purpose.
            You must not, in the use of the Service, violate any laws in your
            jurisdiction (including copyright or trademark laws).
          </li>
          <li>
            Our products are for your personal use. We work hard to provide you
            with a quality product and expect that you won't try to resell,
            copy, or make our products available to others without our written
            permission. If you love our product enough to pass it along, please
            just ask us for permission.
          </li>
        </ol>

        <h3>Our Services</h3>

        <ol>
          <li>
            We consistently add to and improve our products and services. We may
            update, add, or remove products without prior notice.
          </li>
          <li>
            We strive to provide you with an exceptional customer experience and
            are always open to suggestions, comments, and feedback through our
            provided means of communication.
          </li>
          <li>
            We want you to learn and have fun doing so while using our products,
            however we can't guarantee this will be the case.
          </li>
          <li>
            We work diligently to make sure our products and services are
            available and reliable. Because of the nature of our business, this
            is not something we can always control or guarantee.
          </li>
        </ol>

        <h3>Enrollment</h3>

        <p>
          There is a one time fee per Pocket Scholar section, which is valid for
          a full calendar year from the purchase date. Sections may be purchased
          in a bundle at a discounted rate.
        </p>

        <ol>
          <li>A valid credit card is required for payment.</li>
          <li>
            All enrollment fees exclude all taxes, levies, or duties imposed by
            taxing authorities. You are responsible for the payment of all such
            taxes, levies, or duties, excluding only United States (federal or
            state) taxes.
          </li>
        </ol>

        <h3>Cancellation and Deactivation</h3>

        <ol>
          <li>
            If you are not satisfied with your experience at Pocket Scholar,
            please contact{' '}
            <a href="mailto:support@pocketscholar.com">
              support@pocketscholar.com
            </a>{' '}
            to request a refund, as long as you have not completed any more than
            50% of the section.
          </li>
          <li>
            Pocket Scholar, in is sole discretion, has the right to suspend or
            terminate your account and refuse any and all current or future use
            of the Service, or any other Pocket Scholar service, for any reason
            at any time. Such termination of the Service will result in the
            deactivation or deletion of your Account or your access to your
            Account. Pocket Scholar reserves the right to refuse service to
            anyone for any reason at any time.
          </li>
        </ol>

        <h3>Modifications to the Service and Prices</h3>

        <ol>
          <li>
            Pocket Scholar reserves the right at any time and from time to time
            to modify or discontinue, temporarily or permanently, the Service
            (or any part thereof) with or without notice. For instance, the
            Service may temporarily be disabled for occasional maintenance.
          </li>
          <li>
            Prices of all Services are subject to change with 30 days notice
            from Pocket Scholar. Such notice may be provided at any time by
            posting the changes to the Pocket Scholar Site (pocketscholar.com)
            or via email.
          </li>
          <li>
            Pocket Scholar shall not be liable to you or to any third party for
            any modification, price change, suspension or discontinuance of the
            Service.
          </li>
        </ol>

        <h3>Copyright</h3>

        <ol>
          <li>
            The look and feel of the Service is Copyright © 2019 Pocket Scholar
            LLC. All rights reserved. You may not duplicate, copy, or reuse any
            portion of the HTML, CSS, Javascript, or visual design elements or
            concepts without the express written permission from Pocket Scholar.
          </li>
        </ol>

        <h3>General Conditions</h3>

        <ol>
          <li>
            Your use of the Service is at your sole risk. The service is
            provided on an "as is" and "as available" basis.
          </li>
          <li>
            Technical support is only provided to paying account holders and is
            only available via email. Support is only available in English.
          </li>
          <li>
            You understand that Pocket Scholar uses third party vendors and
            hosting partners to provide the necessary hardware, software,
            networking, storage, and related technology required to run the
            Service.
          </li>
          <li>
            You must not modify, adapt, or hack the Service or modify another
            website so as to falsely imply that it is associated with the
            Service, Pocket Scholar, or any other Pocket Scholar service.
          </li>
          <li>
            You agree not to reproduce, duplicate, copy, sell, resell or exploit
            any portion of the Service, use of the Service, or access to the
            Service without the express written permission from Pocket Scholar.
          </li>
          <li>
            Verbal, physical, written, or other abuse (including threats of
            abuse or retribution) of any Pocket Scholar customer, employee,
            member, or officer, will result in immediate account termination.
          </li>
          <li>
            You must not upload, post, host, or transmit unsolicited email, or
            "spam" messages.
          </li>
          <li>
            You must not transmit any code of any destructive nature including,
            but not limited to, worms or viruses.
          </li>
          <li>
            Pocket Scholar does not warrant that (1) the service will meet your
            specific requirements, (2) the service will be uninterrupted,
            timely, secure, or error-free, (3) the results that may be obtained
            from the use of the service will be accurate or reliable, (4) the
            quality of any products, services, information, or other material
            purchased or obtained by you through the service will meet your
            expectations, and (5) any errors in the Service will be corrected.
          </li>
          <li>
            You expressly understand and agree that Pocket Scholar shall not be
            liable for any direct, indirect, incidental, special, consequential
            or exemplary damages, including, damages for loss of profits,
            goodwill, use, data or other intangible losses whatsoever, including
            attorneys' fees, (even if Pocket Scholar has been advised of the
            possibility of such damages), resulting from: (1) the use or the
            inability to use the service; (2) the cost of procurement of
            substitute goods and services resulting from any goods, data,
            information or services purchased or obtained, or messages received
            or transactions entered into through or from the service; (3)
            unauthorized access to or alteration of your transmissions or data;
            (4) statements or conduct of any third party on the service; (5) or
            any other matter relating to the service.
          </li>
          <li>
            The failure of Pocket Scholar to exercise or enforce any right or
            provision of the Terms of Service shall not constitute a waiver of
            such right or provision. The Terms of Service constitutes the entire
            agreement between you and Pocket Scholar and govern your use of the
            Service, superseding any prior agreements between you and Pocket
            Scholar (including any prior versions of the Terms of Service). You
            agree that these Terms of Service and Your use of the Service are
            governed under Delaware law.
          </li>
          <li>
            Questions about the Terms of Service should be sent to
            support@pocketscholar.com.
          </li>
        </ol>
      </div>
    </Tos>
  </Layout>
)

export default TOSPage
